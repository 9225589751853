// src/TimeConverterPage.js
import React, { useState } from 'react';

const TimeConverterPage = () => {
  const [time24, setTime24] = useState('');
  const [time12, setTime12] = useState('');
  const [error, setError] = useState('');

  const convertTo12Hour = () => {
    if (!time24) {
      setError('يرجى إدخال وقت صحيح');
      return;
    }

    const [hours, minutes] = time24.split(':');
    if (hours === undefined || minutes === undefined) {
      setError('يرجى إدخال وقت صحيح');
      return;
    }

    const period = hours >= 12 ? 'م' : 'ص';
    const adjustedHours = hours % 12 || 12;
    setTime12(`${adjustedHours}:${minutes} ${period}`);
    setError('');
  };

  return (
    <div className="container">
      <h1>تحويل الوقت</h1>
      <input
        type="time"
        value={time24}
        onChange={(e) => setTime24(e.target.value)}
      />
      <button onClick={convertTo12Hour}>تحويل إلى 12 ساعة</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {time12 && <h2>الوقت: {time12}</h2>}
    </div>
  );
};

export default TimeConverterPage;
// src/PuzzlePage.js
import React, { useState } from 'react';

const puzzles = [
  { question: "ما هو الشيء الذي لديه مفاتيح ولكن لا يمكنه فتح الأقفال؟", answer: "البيانو" },
  { question: "ما هو الشيء الذي له رأس وذيل ولونه بني وليس له أرجل؟", answer: "القرش" },
  { question: "ما هو الشيء الذي يأتي مرة في الدقيقة ومرتين في اللحظة ولكنه لا يأتي في ألف سنة؟", answer: "حرف الميم" },
  // أضف المزيد من الألغاز هنا
];

const getRandomPuzzle = () => {
  const randomIndex = Math.floor(Math.random() * puzzles.length);
  return puzzles[randomIndex];
};

const PuzzlePage = () => {
  const [puzzle, setPuzzle] = useState(getRandomPuzzle());
  const [showAnswer, setShowAnswer] = useState(false);

  const handleNewPuzzle = () => {
    setPuzzle(getRandomPuzzle());
    setShowAnswer(false);
  };

  return (
    <div className="container">
      <h1>ألغاز عشوائية</h1>
      <p>{puzzle.question}</p>
      {showAnswer && <p><strong>الإجابة:</strong> {puzzle.answer}</p>}
      <button onClick={() => setShowAnswer(!showAnswer)}>
        {showAnswer ? 'إخفاء الإجابة' : 'إظهار الإجابة'}
      </button>
      <button onClick={handleNewPuzzle}>لغز جديد</button>
    </div>
  );
};

export default PuzzlePage;
import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownButton, Form, Button, Container } from 'react-bootstrap';

const AdminDashboard = ({ features, setFeatures }) => {
  const [newUser, setNewUser] = useState('');
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState('');
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [files, setFiles] = useState([]);
  const [newFile, setNewFile] = useState('');
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeFeatures: 0,
  });
  const [settings, setSettings] = useState({
    siteName: 'موقعي',
    adminEmail: 'admin@example.com',
  });

  useEffect(() => {
    const storedUsers = JSON.parse(localStorage.getItem('users')) || ['admin'];
    const storedRoles = JSON.parse(localStorage.getItem('roles')) || [];
    const storedNotifications = JSON.parse(localStorage.getItem('notifications')) || [];
    const storedComments = JSON.parse(localStorage.getItem('comments')) || [];
    const storedFiles = JSON.parse(localStorage.getItem('files')) || [];
    const storedSettings = JSON.parse(localStorage.getItem('settings')) || {
      siteName: 'موقعي',
      adminEmail: 'admin@example.com',
    };
    setUsers(storedUsers);
    setRoles(storedRoles);
    setNotifications(storedNotifications);
    setComments(storedComments);
    setFiles(storedFiles);
    setSettings(storedSettings);
    updateStats(storedUsers, features);
  }, [features]);

  const toggleFeature = (feature) => {
    const updatedFeatures = {
      ...features,
      [feature]: !features[feature],
    };
    setFeatures(updatedFeatures);
    localStorage.setItem('features', JSON.stringify(updatedFeatures));
    updateStats(users, updatedFeatures);
  };

  const addUser = () => {
    if (newUser && !users.includes(newUser)) {
      const updatedUsers = [...users, newUser];
      setUsers(updatedUsers);
      localStorage.setItem('users', JSON.stringify(updatedUsers));
      setNewUser('');
      updateStats(updatedUsers, features);
    }
  };

  const addRole = () => {
    if (newRole && !roles.includes(newRole)) {
      const updatedRoles = [...roles, newRole];
      setRoles(updatedRoles);
      localStorage.setItem('roles', JSON.stringify(updatedRoles));
      setNewRole('');
    }
  };

  const addNotification = () => {
    if (newNotification) {
      const updatedNotifications = [...notifications, newNotification];
      setNotifications(updatedNotifications);
      localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
      setNewNotification('');
    }
  };

  const addComment = () => {
    if (newComment) {
      const updatedComments = [...comments, newComment];
      setComments(updatedComments);
      localStorage.setItem('comments', JSON.stringify(updatedComments));
      setNewComment('');
    }
  };

  const addFile = () => {
    if (newFile) {
      const updatedFiles = [...files, newFile];
      setFiles(updatedFiles);
      localStorage.setItem('files', JSON.stringify(updatedFiles));
      setNewFile('');
    }
  };

  const updateStats = (users, features) => {
    const activeFeaturesCount = Object.values(features).filter(Boolean).length;
    setStats({
      totalUsers: users.length,
      activeFeatures: activeFeaturesCount,
    });
  };

  const handleSettingsChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const saveSettings = () => {
    localStorage.setItem('settings', JSON.stringify(settings));
    alert('تم حفظ الإعدادات بنجاح');
  };

  return (
    <Container>
      <h1>لوحة الإدارة</h1>
      <DropdownButton id="dropdown-basic-button" title="إدارة الميزات">
        <Dropdown.Item>
          <h2>إدارة الميزات</h2>
          <ul>
            {Object.keys(features).map((feature) => (
              <li key={feature}>
                <label>
                  <input
                    type="checkbox"
                    checked={features[feature]}
                    onChange={() => toggleFeature(feature)}
                  />
                  {feature}
                </label>
              </li>
            ))}
          </ul>
        </Dropdown.Item>
        <Dropdown.Item>
          <h2>إدارة المستخدمين</h2>
          <Form>
            <Form.Group controlId="formNewUser">
              <Form.Label>أدخل اسم المستخدم</Form.Label>
              <Form.Control
                type="text"
                value={newUser}
                onChange={(e) => setNewUser(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={addUser}>
              إضافة مستخدم
            </Button>
          </Form>
          <ul>
            {users.map((user, index) => (
              <li key={index}>{user}</li>
            ))}
          </ul>
        </Dropdown.Item>
        <Dropdown.Item>
          <h2>إدارة الأدوار</h2>
          <Form>
            <Form.Group controlId="formNewRole">
              <Form.Label>أدخل اسم الدور</Form.Label>
              <Form.Control
                type="text"
                value={newRole}
                onChange={(e) => setNewRole(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={addRole}>
              إضافة دور
            </Button>
          </Form>
          <ul>
            {roles.map((role, index) => (
              <li key={index}>{role}</li>
            ))}
          </ul>
        </Dropdown.Item>
        <Dropdown.Item>
          <h2>إدارة الإشعارات</h2>
          <Form>
            <Form.Group controlId="formNewNotification">
              <Form.Label>أدخل إشعار جديد</Form.Label>
              <Form.Control
                type="text"
                value={newNotification}
                onChange={(e) => setNewNotification(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={addNotification}>
              إضافة إشعار
            </Button>
          </Form>
          <ul>
            {notifications.map((notification, index) => (
              <li key={index}>{notification}</li>
            ))}
          </ul>
        </Dropdown.Item>
        <Dropdown.Item>
          <h2>إدارة التعليقات</h2>
          <Form>
            <Form.Group controlId="formNewComment">
              <Form.Label>أدخل تعليق جديد</Form.Label>
              <Form.Control
                type="text"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={addComment}>
              إضافة تعليق
            </Button>
          </Form>
          <ul>
            {comments.map((comment, index) => (
              <li key={index}>{comment}</li>
            ))}
          </ul>
        </Dropdown.Item>
        <Dropdown.Item>
          <h2>إدارة الملفات</h2>
          <Form>
            <Form.Group controlId="formNewFile">
              <Form.Label>أدخل اسم الملف</Form.Label>
              <Form.Control
                type="text"
                value={newFile}
                onChange={(e) => setNewFile(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={addFile}>
              إضافة ملف
            </Button>
          </Form>
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file}</li>
            ))}
          </ul>
        </Dropdown.Item>
      </DropdownButton>
      <div className="admin-section">
        <h2>الإحصائيات</h2>
        <p>إجمالي المستخدمين: {stats.totalUsers}</p>
        <p>الميزات النشطة: {stats.activeFeatures}</p>
      </div>
      <div className="admin-section">
        <h2>الإعدادات</h2>
        <Form>
          <Form.Group controlId="formSiteName">
            <Form.Label>اسم الموقع</Form.Label>
            <Form.Control
              type="text"
              name="siteName"
              value={settings.siteName}
              onChange={handleSettingsChange}
            />
          </Form.Group>
          <Form.Group controlId="formAdminEmail">
            <Form.Label>بريد الإدارة</Form.Label>
            <Form.Control
              type="email"
              name="adminEmail"
              value={settings.adminEmail}
              onChange={handleSettingsChange}
            />
          </Form.Group>
          <Button variant="primary" onClick={saveSettings}>
            حفظ الإعدادات
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default AdminDashboard;
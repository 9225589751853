import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { Navbar, Nav, Container, Form } from 'react-bootstrap';
import HomePage from './HomePage';
import AgeCalculator from './AgeCalculator';
import PuzzlePage from './PuzzlePage';
import LotteryPage from './LotteryPage';
import DateConverterPage from './DateConverterPage';
import TimeConverterPage from './TimeConverterPage';
import AdminDashboard from './admin/AdminDashboard';
import AdminLogin from './admin/AdminLogin';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [features, setFeatures] = useState({
    ageCalculator: true,
    puzzles: true,
    lottery: true,
    dateConverter: true,
    timeConverter: true,
  });

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const storedDarkMode = JSON.parse(localStorage.getItem('isDarkMode'));
    return storedDarkMode !== null ? storedDarkMode : false;
  });

  useEffect(() => {
    const storedFeatures = JSON.parse(localStorage.getItem('features')) || {
      ageCalculator: true,
      puzzles: true,
      lottery: true,
      dateConverter: true,
      timeConverter: true,
    };
    setFeatures(storedFeatures);
  }, []);

  useEffect(() => {
    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = JSON.parse(localStorage.getItem('isAuthenticated')) || false;
    setIsAuthenticated(authStatus);
  }, []);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={isDarkMode ? 'App dark-mode' : 'App'}>
      <Router>
        <Navbar bg={isDarkMode ? "dark" : "light"} variant={isDarkMode ? "dark" : "light"} expand="lg">
          <Container>
            <Navbar.Brand href="/">موقعي</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/">الصفحة الرئيسية</Nav.Link>
                <Nav.Link as={Link} to="/age-calculator">حاسبة العمر</Nav.Link>
                <Nav.Link as={Link} to="/puzzles">ألغاز عشوائية</Nav.Link>
                <Nav.Link as={Link} to="/lottery">قرعة</Nav.Link>
                <Nav.Link as={Link} to="/date-converter">تحويل التاريخ</Nav.Link>
                <Nav.Link as={Link} to="/time-converter">تحويل الوقت</Nav.Link>
              </Nav>
              <Form.Check 
                type="switch"
                id="dark-mode-switch"
                label="الوضع الليلي"
                checked={isDarkMode}
                onChange={toggleDarkMode}
              />
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            {features.ageCalculator && <Route path="/age-calculator" element={<AgeCalculator />} />}
            {features.puzzles && <Route path="/puzzles" element={<PuzzlePage />} />}
            {features.lottery && <Route path="/lottery" element={<LotteryPage />} />}
            {features.dateConverter && <Route path="/date-converter" element={<DateConverterPage />} />}
            {features.timeConverter && <Route path="/time-converter" element={<TimeConverterPage />} />}
            <Route path="/admin-login" element={<AdminLogin setIsAuthenticated={setIsAuthenticated} />} />
            {isAuthenticated && <Route path="/admin/dashboard" element={<AdminDashboard features={features} setFeatures={setFeatures} />} />}
          </Routes>
        </Container>
      </Router>
    </div>
  );
}

export default App;

// src/AgeCalculator.js
import React, { useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';

const AgeCalculator = () => {
  const [birthDate, setBirthDate] = useState('');
  const [age, setAge] = useState(null);

  const calculateAge = () => {
    const birthDateObj = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDifference = today.getMonth() - birthDateObj.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    setAge(age);
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="auto">
          <h1>حاسبة العمر</h1>
          <Form>
            <Form.Group controlId="formBirthDate">
              <Form.Label>تاريخ الميلاد</Form.Label>
              <Form.Control
                type="date"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
                className="form-control"
              />
            </Form.Group>
            <Button variant="primary" onClick={calculateAge} className="mt-3">
              احسب العمر
            </Button>
          </Form>
          {age !== null && <h2 className="mt-3">عمرك هو: {age}</h2>}
        </Col>
      </Row>
    </Container>
  );
};

export default AgeCalculator;
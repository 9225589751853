// src/LotteryPage.js
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

const LotteryPage = () => {
  const [participants, setParticipants] = useState('');
  const [winner, setWinner] = useState(null);

  const handleDraw = () => {
    const participantsArray = participants.split(',').map(name => name.trim());
    const randomIndex = Math.floor(Math.random() * participantsArray.length);
    setWinner(participantsArray[randomIndex]);
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md="auto">
          <h1>قرعة</h1>
          <Form>
            <Form.Group controlId="formParticipants">
              <Form.Label>أدخل أسماء المشاركين مفصولة بفواصل</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={participants}
                onChange={(e) => setParticipants(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleDraw}>
              إجراء القرعة
            </Button>
          </Form>
          {winner && <h2 className="mt-3">الفائز هو: {winner}</h2>}
        </Col>
      </Row>
    </Container>
  );
};

export default LotteryPage;
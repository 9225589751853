// src/HomePage.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const HomePage = () => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="auto">
          <h1>مرحبًا بكم في موقعي</h1>
          <p>هذا هو الموقع الرئيسي حيث يمكنك الوصول إلى جميع الميزات المتاحة.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default HomePage;
// src/DateConverterPage.js
import React, { useState } from 'react';
import moment from 'moment-hijri';

const DateConverterPage = () => {
  const [gregorianDate, setGregorianDate] = useState('');
  const [hijriDate, setHijriDate] = useState('');

  const convertToHijri = () => {
    if (gregorianDate) {
      const hijri = moment(gregorianDate, 'YYYY-MM-DD').format('iYYYY/iMM/iDD');
      setHijriDate(hijri);
    }
  };

  return (
    <div className="container">
      <h1>تحويل التاريخ</h1>
      <input
        type="date"
        value={gregorianDate}
        onChange={(e) => setGregorianDate(e.target.value)}
      />
      <button onClick={convertToHijri}>تحويل إلى هجري</button>
      {hijriDate && <h2>التاريخ الهجري: {hijriDate}</h2>}
    </div>
  );
};

export default DateConverterPage;